import React from 'react';
import styles from './styles.module.css';

const Footer = (props) => {
  return (
    <div className={styles.wrapper}>
      <span>© {new Date().getFullYear()} Metabolic Healthcare Ltd</span>

      <a href="https://www.lloydsdirect.co.uk" className={styles.echoLink}>
        lloydsdirect.co.uk
      </a>
    </div>
  );
};

export default Footer;
