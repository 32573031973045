import React from 'react';
import { Link } from 'gatsby';
import logo from './logo.svg';

import styles from './styles.module.css';

const Header = () => (
  <div className={styles.wrapper}>
    <Link to="/" className={styles.logo}>
      <img className={styles.logoImage} src={logo} alt="LloydsDirect logo" />
      <span className={styles.logoType}>Building LloydsDirect</span>
    </Link>
  </div>
);

export default Header;
